@import './src/styles/variables.module';

.infoWrapper {
  background-image: url('../../../static/bgInfo.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  height: calc(100vh);
  width: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  &::after {
    background: rgb(18,21,23);
    background: -moz-linear-gradient(0deg, rgba(18,21,23,1) 0%, rgba(18,21,23,0) 100%);
    background: -webkit-linear-gradient(0deg, rgba(18,21,23,1) 0%, rgba(18,21,23,0) 100%);
    background: linear-gradient(0deg, rgba(18,21,23,1) 0%, rgba(18,21,23,0) 100%);
    height: 166px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    content: '';
  }

  &::before {
    background: rgb(18,21,23);
    background: -moz-linear-gradient(180deg, rgba(18,21,23,1) 0%, rgba(18,21,23,0) 100%);
    background: -webkit-linear-gradient(180deg, rgba(18,21,23,1) 0%, rgba(18,21,23,0) 100%);
    background: linear-gradient(180deg, rgba(18,21,23,1) 0%, rgba(18,21,23,0) 100%);
    height: 60px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    content: '';
  }
}

.infoTitle {
  font-size: 48px;
  margin: 0;
  padding: 0;
  font-weight: 700;
  & span {
    color: $Color_Green500;
  }
}

.infoSubtitle {
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
}

.infoSection {
  & h4 {
    font-size: 48px;
    font-weight: bold;
    line-height: 80px;
    margin: 0;
    color: $Color_Green500;
  }

  & p {
    font-size: 24px;
    line-height: 40px;
  }
}

@media (width < 960px) {

  .infoWrapper {
    height: auto;
    padding: 2rem 0;
  }
  .infoTitle {
    font-size: 24px;
    line-height: 32px;
    margin: 0 0 1rem 0;
  }


  .infoSubtitle {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  }

}
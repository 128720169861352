@import './src/styles/variables.module';

.optionsWrapper {
  width: 100%;
}

.optionsTitle {
  font-size: 48px;
  & p {
    margin: 0;
    padding: 0;
    font-weight: 700;
    & span {
      color: $Color_Green500;
    }
  }
}

.optionsList {
  display: flex;
  list-style: none;
  padding: 20px 0;
  margin: 4rem auto;


  & > li {
    gap: 2rem;
    overflow: hidden;
    margin: 0 2rem 0 0;
    padding: 1rem 0;
    max-width: 254px;


  }
}

.optionsListInner {
  padding: 0 1rem;
  border-radius: 4px;
  height: 202px;
  border: 2px solid #363E45;
  position: relative;

  & input {
    display: block;
    position: absolute;
    top: 50%;
    margin: -35px 0 0 0;
    border: none;
    height: 64px;
    z-index: 2;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    width: 152px;
    padding: 0 1rem;
    font-size: 26px;
    transition: all 0.3s;

  }
}

.paymentList {
  padding: 0;
  list-style: none;
  margin: -1rem 0;
  position: relative;

  .paymentListItemActive {
    background: #fff;
    color: #1A1F22;
  }
}
.paymentListItem {
  background: $Color_Gray500;
  border-radius: 4px;
  display: flex;
  width: 220px;
  cursor: pointer;
  user-select: none;

  align-items: center;
  font-size: 1.5rem;

  & svg {
    margin: 0 0 0 1rem;
  }
  & span {
    padding: 1rem;
  }
}

.currencyListItem {
  justify-content: flex-end;
  background: $Color_Gray500;
  border-radius: 4px;
  display: flex;
  margin: 0 0 1rem auto;
  cursor: pointer;
  user-select: none;
  height: 64px;
  align-items: center;
  font-size: 1.5rem;
  width: 64px;

  & span {
    margin: 12px;
    background-color: #ccc;
    border-radius: 100%;
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    display: block;
    align-items: center;
    color: #fff;
    font-weight: bold;
    text-align: center;
    line-height: 40px;
  }
}


.cardBlock {
  padding: 0;
  list-style: none;
  margin: -1rem 0;
  max-width: 230px;

  & svg {
    background: $Color_Gray500;
    border-radius: 4px;
    rect {
      &:first-of-type{
        fill: #0466C8;
        width: 0;
        animation-name: moveCard;
        animation-duration: 4000ms;
        animation-delay: 4000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        position: relative;
      }
    }

    &:hover {
      rect {
        &:first-of-type {
          fill: #0466C8;
          width: 100%;
          animation-play-state: paused;
        }
      }
    }
  }



}

.cardBlockLock {
  width: 223px;
  display: flex;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  margin: 0.3rem 0 -1rem 0;
  & svg {
    background-color: #fff;
    border-radius: 4px;
    padding: 0.5rem;
    min-width: 64px;
    max-width: 64px;
    margin: 0 1rem 0 0;
    height: 64px;
  }
}

.currencyList {
  padding: 0;
  list-style: none;
  min-width: 214px;
  margin: -1rem 0;


}

@media (width < 960px) {
  .optionsList {
    display: block;
    & > li {
      margin: 2rem auto;


    }
  }

  .optionsTitle {
    font-size: 24px;
    line-height: 32px;
  }

  .paymentList {
    & li {
      width: 100%;
    }
  }

  .currencyList {

    & .currencyListItemActive {
      width: auto;
      & input {
        display: block;
        width: 100%;
      }
    }
  }


  .cardBlock {
    margin: -1rem auto;
    & svg {
      width: 100%;
    }
  }


  .cardBlockLock {
    width: 223px;
  }
}

@keyframes moveCard {
  0% {
    width: 10%;
  }
  33% {
    width: 34%;
  }

  66% {
    width: 90%
  }

  100% {
    width: 10%;
  }
}

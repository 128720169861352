@import "../../styles/variables.module";

.caret {
  display: inline-block;
  width: 2px;
  height: 1em;
  margin-left: 2px;
  margin-bottom: -2px;
  animation: blink-caret .50s step-end infinite;
}

.fake {
  visibility: hidden;
}

.fake, .real {
  grid-area: 1 / 1;
}

.root {
  position: relative;
  display: grid;

  &:not(.isActive) {
    .caret {
      visibility: hidden;
    }
  }
}

@keyframes blink-caret {
  from, to {
    background-color: transparent;
  }

  50% {
    background-color: currentColor;
  }
}

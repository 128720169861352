@import '../../styles/variables.module';

.heroWrapper {
  background-image: url('../../../static/bgHeader.jpg');
  background-size: cover;
  height: calc(100vh - 64px);
  width: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  &::after {
    background: rgb(18,21,23);
    background: -moz-linear-gradient(0deg, rgba(18,21,23,1) 0%, rgba(18,21,23,0) 100%);
    background: -webkit-linear-gradient(0deg, rgba(18,21,23,1) 0%, rgba(18,21,23,0) 100%);
    background: linear-gradient(0deg, rgba(18,21,23,1) 0%, rgba(18,21,23,0) 100%);
    height: 166px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    content: '';
  }
}

.scrollDown {
  position: absolute;
  bottom: 2rem;
  text-align: center;
  z-index: 3;
  animation: jumpInfinite 1.5s infinite;
  width: 100%;
  left: 0;
}

.heroTitle {
  font-size: 48px;
  margin: 0;
  padding: 0;
  font-weight: 700;
  & span {
    color: $Color_Green500;
  }
}

.heroSection {
  & h4 {
    font-size: 48px;
    font-weight: bold;
    line-height: 80px;
    margin: 0;
    color: $Color_Green500;
  }

  & p {
    font-size: 24px;
    line-height: 40px;
  }
}

.typeWriting {

}

@media (width < 960px) {


  .typeWriting {
    &>div {
      display: inline-block;
    }
    & h4, p {
      overflow: hidden;
      margin: 0;
      display: inline-block;
    }
  }

  .heroWrapper {
    height: calc(100vh - 56px);
    background-size: cover;
    padding: 0 1rem;

  }
  .heroTitle {
    font-size: 24px;
  }

  .heroSection {
    & h4 {
      font-size: 24px;
      line-height: 32px;
    }

    & p {
      line-height: 32px;
      padding: 0;
    }
  }
}

@keyframes  jumpInfinite {
  0% {
    bottom: 2rem;
  }
  50% {
    bottom: 3rem;
  }
  100% {
    bottom: 2rem;
  }
}

@import './src/styles/variables.module';

.cardsWrapper {
  width: 100%;
  overflow-x: hidden;
}

.cardsTitle {
  font-size: 48px;
  & p {
    margin: 0;
    padding: 0;
    font-weight: 700;
    & span {
      color: $Color_Green500;
    }
  }
}

.cardsListSlider {
}

.cardsListSliderSlide {
  width: 300px;
  min-width: 300px;
}

.cardsList {

}

.cardsListItem {
  height: 680px;
  padding: 2rem;
  background-color: $Color_Gray500;
  position: relative;
  & svg {
    width: 72px;
    height: 72px;
  }

  & h3 {
    font-size: 32px;
    line-height: 52px;
    font-weight: 700;
  }

  & p {
    font-size: 24px;
    font-weight: 400;
  }

  & a {
    position: absolute;
    bottom: 2rem;
    width: calc(100% - 4rem);
  }
}

.cardsContactItem {
  font-size: 20px;
  & strong {
    display: block;
  }
}

.paymentSystemList {
  .paymentSystem {
    display: flex;
    justify-content: center;
  }

  & svg {
    width: auto;
    max-width: 190px;
    max-height: 110px;
    height: auto;
  }
}
@media (width < 1480px) {
  .cardsListItem {
    & p {
      font-size: 21px;
    }
  }
}

@media (width < 960px) {
  .cardsTitle {
    font-size: 24px;
    line-height: 32px;
  }

  .cardsListItem {
    margin: 1rem;

    & svg {
      width: 48px;
      height: 48px;
    }

    & h3 {
      font-size: 20px;
      line-height: 28px;
    }

    & p {
      font-size: 16px;
    }
  }

  .paymentSystemList {
    margin: 4rem 0;
  }
}

